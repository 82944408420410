import React from "react";
import Main from "./Main";
import Education from "./Education";
import Faq from "./Faq";
import Question from "./Question";

const Home = () => {
  return (
    <>
      {/* <Main />
      <Education />
      <Faq /> */}
      <Question />
    </>
  );
};

export default Home;
